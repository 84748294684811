/* eslint-disable no-unused-vars */

import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../components/AuthContext/AuthContext';
import {useState} from 'react'
import Slider from 'react-slick';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  HomePageWrapperStyled,  
  BannerStyled,
  BannerBgStyled,
  BannerContentStyled,
  BannerHeading,
  BannerSubheading,  
  HomePageContentStyled
} from './HomePageFrStyled';
import {
  Login,
  Register,
  PasswordReset,
  Purchase,
  // MyAccount,
  Subscriptions,
  PaymentInfo,
  UpdateProfile,
  Capture,
  Consents,
  CheckoutConsents,
  TransactionList,
  SubscriptionSwitches,
  Checkout,
  PlanDetails,
  Config
} from '@cleeng/mediastore-sdk';

import Header from '../../components/Header/Header';
import { usePopupsContext } from '../../components/Popups/Popups';





import $ from 'jquery';

const locale = 'fr';

$( document ).ready(function() {  
  if (window.location.href.indexOf("/fr") > -1) {
      
    setTimeout(function(){        
    $('#langSelector').html('en');
      $('#langSelector').attr('href','/');
      $('#backToHome').attr('href','/fr');
      $('#backToHomeURL').attr('href','/fr');
      $('.appTextLogout').html('SE DÉCONNECTER');
      $('.appText').html('Visionner');
      $('.appTextSignIn').html('S\'ABONNER À WHITEBOX PLAY');
      $('#webapplinksignup').html('CONNEXION');
    },500);      
  }else{

    setTimeout(function(){        
        $('#langSelector').html('fr');
        $('#langSelector').attr('href','/fr');
        $('#backToHome').attr('href','/');
        $('#backToHomeURL').attr('href','/');
     
        $('.appTextLogout').html('Sign out');
        $('.appText').html('Watch');
    },500);
  }

  var lastScrollTop;
  var st ;

  $(window).scroll(function() {    
    var st = $(this).scrollTop();
    var width = $(window).width();
    var height = $(window).height();
    var scroll = $(window).scrollTop();
    var limit = 0.6; //implies 60 vh or 60% of viewport height

    
    
    var $minTop = $('#upcoming').position().top
    var $maxTop = $('#subscribe').position().top

    //console.log('scroll :'+scroll);
    //console.log('$minTop :'+$minTop);
    //console.log('$maxTop :'+$maxTop);

    st = $(this).scrollTop();    

    if (width <= 1024){
        if(st < lastScrollTop) {
            
            if (scroll >= $maxTop ){
                if (window.location.href.indexOf("/fr") > -1) {
                    $("body").removeClass("darkHeader");
                    $('.btn-sign-in').removeClass('btn-red');
                    $('.btn-sign-in a').html('Connexion');         
                }else{
                    $("body").removeClass("darkHeader");
                    $('.btn-sign-in').removeClass('btn-red');
                    $('.btn-sign-in a').html('Sign in');         
                }               
            }else{            
                if (window.location.href.indexOf("/fr") > -1) {
                    $('.btn-sign-in').addClass('btn-red');
                    $("body").addClass("darkHeader");                
                    $('.btn-sign-in a').html('S\'abonner&nbsp;à&nbsp;WhiteBox&nbsp;Play');
                }else{
                    $('.btn-sign-in').addClass('btn-red');
                    $("body").addClass("darkHeader");                
                    $('.btn-sign-in a').html('Sign&nbsp;up&nbsp;to&nbsp;WhiteBox&nbsp;Play');
                }
            }
        }else{
            if (scroll >= $minTop ) {
                console.log('1');
                if (scroll >= $maxTop ){
                    if (window.location.href.indexOf("/fr") > -1) {
                        $("body").removeClass("darkHeader");
                        $('.btn-sign-in').removeClass('btn-red');
                        $('.btn-sign-in a').html('Connexion');
                    }else{
                        $("body").removeClass("darkHeader");
                        $('.btn-sign-in').removeClass('btn-red');
                        $('.btn-sign-in a').html('Sign in');
                    }
                }else{
                    if (window.location.href.indexOf("/fr") > -1) {
                        $('.btn-sign-in').addClass('btn-red');
                        $("body").addClass("darkHeader");                    
                        $('.btn-sign-in a').html('S\'abonner&nbsp;à&nbsp;WhiteBox&nbsp;Play');
                    }else{
                        $('.btn-sign-in').addClass('btn-red');
                        $("body").addClass("darkHeader");                    
                        $('.btn-sign-in a').html('Sign&nbsp;up&nbsp;to&nbsp;WhiteBox&nbsp;Play');
                    }
                }
            }else{
                console.log(4);
                if (scroll >= $maxTop ){
                    
                }else{
                    if (window.location.href.indexOf("/fr") > -1) {
                        $("body").removeClass("darkHeader");
                        $('.btn-sign-in').removeClass('btn-red');
                        $('.btn-sign-in a').html('Connexion');   
                    }else{
                        $("body").removeClass("darkHeader");
                        $('.btn-sign-in').removeClass('btn-red');
                        $('.btn-sign-in a').html('Sign in');   
                    }
                }
            }
        }        
    }
    var lastScrollTop = st;
});




    // When the user scrolls the page, execute myFunction
    window.onscroll = function() {myFunction()};

    // Get the header
    var header = document.getElementById("header");

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
    }
});





const HomePageFr = () => {
    const [sliderRef, setSliderRef] = useState(null);
    const sliderSettings = {
        // removes default buttons
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1600,
              settings: {
               slidesToShow: 3,
              }
            },
            {
                breakpoint: 1024,
                settings: {
                 slidesToShow: 2,
                }
            },
            {
              breakpoint: 600,
              settings: {
               slidesToShow: 1,
              }
             }
          ]
      };

      const sliderSettings2 = {
        // removes default buttons
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
               slidesToShow: 3,
              }
            },
            {
                breakpoint: 900,
                settings: {
                 slidesToShow: 2,
                }
            },
            {
              breakpoint: 600,
              settings: {
               slidesToShow: 2,
              }
             },
             {
                breakpoint: 350,
                settings: {
                 slidesToShow: 1,
                }
               }
          ]
      };

      
Config.setOffer('S874998139_US');
  let history = useHistory();
  const { showModal } = usePopupsContext();
  const { isAuthenticated } = useContext(AuthContext);
  const { alreadyHaveAccess} = 'test123';
  
  const showCards = [
    {
      imageSrc:
        '/images/whitebox/home/slide1/ConcertExclusifs_FR.jpg'
    },
    {
      imageSrc:
        '/images/whitebox/home/slide1/corneilRemix.png'
    },
    {
      imageSrc:
        '/images/whitebox/home/slide1/MarjolaineMorasse_OnSeDeracine.jpg',
    },
    {
      imageSrc:
        '/images/whitebox/home/slide1/RyanKennedy.png',     
    },
  ]

  
  const posterCards = [
    {
        imageSrc:
          '/images/whitebox/home/slide2/MarieMai.png',    
          name:'Marie-Mai',    
      },
    {
      imageSrc:
        '/images/whitebox/home/slide2/RyanKennedy.jpg',
        name:'Ryan Kennedy',   
    },
    {
      imageSrc:
        '/images/whitebox/home/slide2/Britanny.png',
        name:'Brittany Kennell',   
    },
    {
      imageSrc:
        '/images/whitebox/home/slide2/ZachZoya.jpg',
        name:'Zach Zoya',   
    },
    
    {
        imageSrc:
          '/images/whitebox/home/slide2/LesSoeursBoulay.jpg',  
        name:'Les Soeurs Boulay',   
      },
  ]

  return (
    
    <HomePageWrapperStyled>
      <Header />        
        <section id='home' className="hero heroFullscreen bg-parallax" data-jarallax-video="mp4:https://gvpd.zype.com/617fd161a8d0cf0001a5e680/63eff106faeda80001d1b6d2/640660d43cbba000012097af/58cb16a2e43f90148d002972/1c507115-f217-4bea-b22d-6d553d312926.mp4" data-jarallax-original-styles="null">
            <div className='hero-overlay'></div>
            <div className="d-flex align-items-center">                
                <div className="container">
                    <div className="row">
                        <div className=" col-md-12 force-desktop-only">
                            <h1 className="main-title ">VIVEZ<br></br> LA MUSIQUE DE FAÇON<br></br> SPECTACULAIRE !</h1>
                            <h2>Concerts sur demande, séries originales, événements en direct & accès exclusif à l’arrière-scène.</h2>
                            <div><img className="tv-mobile" src="./images/whitebox/bgtv.png" /></div>
                            <div><a href="https://app.whiteboxplay.com" className=" btn-red">COMMENCEZ&nbsp;VOTRE&nbsp;ESSAI&nbsp;GRATUIT&nbsp;DE&nbsp;30&nbsp;JOURS</a></div>
                            <p>APRÈS VOTRE ESSAI GRATUIT, L'ABONNEMENT DE WHITEBOX PLAY EST<br></br> SEULEMENT 7,99$/MOIS.RÉSILIATION POSSIBLE À TOUT MOMENT.</p>                        
                        </div>
                        <div className=" col-md-12 force-mobile-only">
                            <h1 className="main-title">VIVEZ LA MUSIQUE DE FAÇON SPECTACULAIRE&nbsp;!</h1>
                            <h2>Concerts sur demande, séries originales, événements en direct & accès exclusif à l’arrière-scène.</h2>
                            <div><img className="tv-mobile" src="./images/whitebox/bgtv.png" /></div>
                            <div><a href="https://app.whiteboxplay.com" className=" btn-red">COMMENCEZ&nbsp;VOTRE&nbsp;ESSAI&nbsp;GRATUIT&nbsp;DE&nbsp;30&nbsp;JOURS</a></div>
                            <p>APRÈS VOTRE ESSAI GRATUIT, L'ABONNEMENT DE WHITEBOX PLAY EST SEULEMENT 7,99$/MOIS.RÉSILIATION POSSIBLE À TOUT MOMENT.</p>                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="upcoming" className="pt90 pb60 ">
            <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">ON PARTAGE VOTRE PASSION DE LA MUSIQUE</h3>
                        <h4 className="force-desktop-only">Avec nos concerts en direct, nos séries exclusives et bien plus encore</h4>
                        <h4 className="force-mobile-only">Avec nos concerts en direct, nos séries exclusives et <strong>bien plus encore&nbsp;!</strong></h4>
                    </div>
     
                    <div className=" col-md-12 listing-image">
                        <div className='content'>
                            
                            <Slider ref={setSliderRef} {...sliderSettings}>
                            {showCards.map((card, index) => (
                            <div key={index}>            
                                <img className="poster-home" src={card.imageSrc}></img>                                
                            </div>
                            ))}
                            </Slider>
                        </div>       
                    </div>
                </div>
            </div>
        </section>
        <section id="lives" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">ON RECONNECTE LA MUSIQUE ET LES FANS</h3>
                        <h4 className="force-desktop-only">Notre mission, reconnecter les créateurs musicaux à leur communauté de fans en produisant et en diffusant des expériences de grande qualité, exclusives et authentiques.</h4>
                        <h4 className="force-mobile-only">Notre mission, reconnecter les créateurs musicaux à leur communauté de fans<strong>en produisant et en diffusant des expériences de grande qualité, exclusives et authentiques.</strong></h4>
                    </div>
                    <div className=" col-md-12 listing-image">                               
                    <div className='content'>
                            
                            <Slider ref={setSliderRef} {...sliderSettings2}>
                            {posterCards.map((card, index) => (
                            <div key={index}>
                                <div className="poster-wrapper">
                                <img className="poster-home" src={card.imageSrc}></img>
                                <span>{card.name}</span>
                                </div>            
                            </div>
                            ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>        

        <section id="arrivals" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">À VENIR</h3>
                        <h3 className="main-title-2"><strong>Songwriters</strong></h3>
                        <h4>Des artistes se rencontrent pour la première fois et sont mis au défi de composer une nouvelle chanson en 24h tout en étant isolés du monde dans un chalet au milieu de nul part.</h4>
                    </div>                    
                </div>
            </div>
        </section>
        <section id="devices" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">DIFFUSION EN CONTINU SUR TOUS LES ÉCRANS</h3>                        
                        <h4 className="desktop-only">Il est plus facile que jamais de regarder sur votre téléphone, votre tablette, votre ordinateur ou votre lecteur multimédia en continu.</h4>
                        <div className="desktop-only">
                            <ul className="listing-img-device">
                                <li className="single-img-device"><img src="./images/whitebox/devices/iphone.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/ipad.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/appletv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/android.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/androidtv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/google-play.png" /></li>
                                <li className="single-img-device"><img className="firetv" src="./images/whitebox/devices/firetv.png" /></li>
                                <li className="single-img-device"><img className="amazonAppStore" src="./images/whitebox/devices/amazonappstore.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/roku.png" /></li>
                                <li className="single-img-device"><img className="chromecast" src="./images/whitebox/devices/chromecast.png" /></li>
                            </ul>
                        </div>
                        <div className="btn-link-app btn-red"><a href="https://app.whiteboxplay.com">TÉLÉCHARGER L'APPLICATION WHITEBOX PLAY</a></div>
                        <div className="mobile-only">
                            <ul className="listing-img-device">
                                <li className="single-img-device"><img src="./images/whitebox/devices/iphone.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/ipad.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/appletv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/android.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/androidtv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/google-play.png" /></li>
                                <li className="single-img-device"><img className="firetv" src="./images/whitebox/devices/firetv.png" /></li>
                                <li className="single-img-device"><img className="amazonAppStore" src="./images/whitebox/devices/amazonappstore.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/roku.png" /></li>
                                <li className="single-img-device"><img className="chromecast" src="./images/whitebox/devices/chromecast.png" /></li>
                            </ul>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
        <section id="subscribe" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">C'EST PARTI POUR UN<br></br>MARATHON DE CONTENU MUSICAL&nbsp;!</h3>                        
                        <h3 className="main-title-sub">ABONNEZ-VOUS MAINTENANT</h3>                        
                        <div className="btn-black-wrapper"><div className="btn-subscribe btn-black"><a href="https://app.whiteboxplay.com">COMMENCEZ&nbsp;VOTRE&nbsp;ESSAI&nbsp;GRATUIT&nbsp;DE&nbsp;30&nbsp;JOURS</a></div></div>
                        <h4>L'ABONNEMENT DE WHITEBOX PLAY EST SEULEMENT 7,99$/MOIS, APRÈS VOTRE ESSAI GRATUIT. RÉSILIATION POSSIBLE À TOUT MOMENT.</h4>
                    </div>                    
                </div>
            </div>
        </section>
        <footer id="footer" className="footer  pt90 pb90">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">                        
                        <div className="btn-red"><a target="_blank" rel="noreferrer" href="https://whiteboxplay.zendesk.com/hc/fr">BESOIN D'AIDE&nbsp;?</a></div>
                        <h5>Nous sommes là pour vous aider 24/7 </h5>
                        <span>&copy;WHITEBOX PLAY 2023</span>
                        <div id="mobile-lang"><a className="lang" href="/">English</a></div>
                    </div>
                </div>
            </div>
        </footer>
      <HomePageContentStyled />
    </HomePageWrapperStyled>
  )
}



export default HomePageFr;
