/* eslint-disable no-unused-vars */

import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import {useState} from 'react'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AuthContext from '../../components/AuthContext/AuthContext';
import {
  HomePageWrapperStyled,  
  BannerStyled,
  BannerBgStyled,
  BannerContentStyled,
  BannerHeading,
  BannerSubheading,  
  HomePageContentStyled
} from './HomePageStyled';
import {
  Login,
  Register,
  PasswordReset,
  Purchase,
  // MyAccount,
  Subscriptions,
  PaymentInfo,
  UpdateProfile,
  Capture,
  Consents,
  CheckoutConsents,
  TransactionList,
  SubscriptionSwitches,
  Checkout,
  PlanDetails,
  Config
} from '@cleeng/mediastore-sdk';

import Header from '../../components/Header/Header';
import { usePopupsContext } from '../../components/Popups/Popups';

import $ from 'jquery';
const locale = 'en';


$( document ).ready(function() {  

    var lastScrollTop;
    var st ;
  
    $(window).scroll(function() {    
      var st = $(this).scrollTop();
      var width = $(window).width();
      var height = $(window).height();
      var scroll = $(window).scrollTop();
      var limit = 0.6; //implies 60 vh or 60% of viewport height
  
      
      var $minTop = $('#upcoming').position().top
      var $maxTop = $('#subscribe').position().top
  
      //console.log('scroll :'+scroll);
      //console.log('$minTop :'+$minTop);
      //console.log('$maxTop :'+$maxTop);
  
      st = $(this).scrollTop();
      
  
      if (width <= 1024){
          if(st < lastScrollTop) {              
              if (scroll >= $maxTop ){
                  //console.log('3');
                  $("body").removeClass("darkHeader");
                  $('.btn-sign-in').removeClass('btn-red');
                  $('.btn-sign-in a').html('Sign in');                        
              }else{            
                  //console.log('4');
                  $('.btn-sign-in').addClass('btn-red');
                  $("body").addClass("darkHeader");
                  
                  $('.btn-sign-in a').html('Sign up to WhiteBox Play');
              }
          }else{
              if (scroll >= $minTop ) {
                  //console.log('1');
                  if (scroll >= $maxTop ){
                      $("body").removeClass("darkHeader");
                      $('.btn-sign-in').removeClass('btn-red');
                      $('.btn-sign-in a').html('Sign in');       
                  }else{
                      $('.btn-sign-in').addClass('btn-red');
                      $("body").addClass("darkHeader");
                      
                      $('.btn-sign-in a').html('Sign up to WhiteBox Play');
                  }
              }else{
                  //console.log(4);
                  if (scroll >= $maxTop ){
                      //console.log('3');
                  }else{
                      //console.log('2');
                      $("body").removeClass("darkHeader");
                      $('.btn-sign-in').removeClass('btn-red');
                      $('.btn-sign-in a').html('Sign in');   
                  }
              }
          }        
      }
      var lastScrollTop = st;
  });
  
  
  
  
      // When the user scrolls the page, execute myFunction
      window.onscroll = function() {myFunction()};
  
      // Get the header
      var header = document.getElementById("header");
  
      // Get the offset position of the navbar
      var sticky = header.offsetTop;
  
      // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
      function myFunction() {
      if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
      } else {
          header.classList.remove("sticky");
      }
      }
  });
  
  

const HomePage = () => {
    const [sliderRef, setSliderRef] = useState(null);
    const sliderSettings = {
        // removes default buttons
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1600,
              settings: {
               slidesToShow: 3,
              }
            },
            {
                breakpoint: 1024,
                settings: {
                 slidesToShow: 2,
                }
            },
            {
              breakpoint: 600,
              settings: {
               slidesToShow: 1,
              }
             }
          ]
      };

      const sliderSettings2 = {
        // removes default buttons
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
               slidesToShow: 3,
              }
            },
            {
                breakpoint: 900,
                settings: {
                 slidesToShow: 2,
                }
            },
            {
              breakpoint: 600,
              settings: {
               slidesToShow: 2,
              }
             },
             {
                breakpoint: 350,
                settings: {
                 slidesToShow: 1,
                }
               }
          ]
      };
Config.setOffer('S874998139_US');
  let history = useHistory();
  const { showModal } = usePopupsContext();
  const { isAuthenticated } = useContext(AuthContext);

  const showCards = [
    {
      imageSrc:
        '/images/whitebox/home/slide1/ExclusiveConcerts_EN.jpg'
    },
    {
      imageSrc:
        '/images/whitebox/home/slide1/corneilRemix.png'
    },
    {
      imageSrc:
        '/images/whitebox/home/slide1/MarjolaineMorasse_OnSeDeracine.jpg',
    },
    {
      imageSrc:
        '/images/whitebox/home/slide1/RyanKennedy.png',     
    },
  ]

  
  const posterCards = [
    {
        imageSrc:
          '/images/whitebox/home/slide2/MarieMai.png',    
          name:'Marie-Mai',    
      },
    {
      imageSrc:
        '/images/whitebox/home/slide2/RyanKennedy.jpg',
        name:'Ryan Kennedy',   
    },
    {
      imageSrc:
        '/images/whitebox/home/slide2/Britanny.png',
        name:'Brittany Kennell',   
    },
    {
      imageSrc:
        '/images/whitebox/home/slide2/ZachZoya.jpg',
        name:'Zach Zoya',   
    },
    
    {
        imageSrc:
          '/images/whitebox/home/slide2/LesSoeursBoulay.jpg',  
        name:'Les Soeurs Boulay',   
      },
  ]
  return (
    
    <HomePageWrapperStyled>
      <Header />        
        <section id='home' className="hero heroFullscreen bg-parallax" data-jarallax-video="mp4:https://gvpd.zype.com/617fd161a8d0cf0001a5e680/63eff106faeda80001d1b6d2/640660d43cbba000012097af/58cb16a2e43f90148d002972/1c507115-f217-4bea-b22d-6d553d312926.mp4" data-jarallax-original-styles="null">
            <div className='hero-overlay'></div>
            <div className="d-flex align-items-center">                
                <div className="container">
                    <div className="row">
                        <div className=" col-md-12 force-desktop-only">
                            <h1 className="main-title ">EXPERIENCE<br></br>MUSIC IN THE MOST<br></br> SPECTACULAR WAYS!</h1>
                            <h2>On-demand concerts, live events, original series & backstage access.</h2>
                            <div><img className="tv-mobile" src="./images/whitebox/bgtv.png" /></div>
                            <div><a href="https://app.whiteboxplay.com" className=" btn-red">START&nbsp;YOUR&nbsp;30-DAY&nbsp;TRIAL</a></div>
                            <p>AFTER YOUR FREE TRIAL, WHITEBOX PLAY SUBSCRIPTION<br></br>IS ONLY 7,99$/MONTH. CANCEL ANYTIME.</p>                        
                        </div>
                        <div className=" col-md-12 force-mobile-only">
                            <h1 className="main-title">EXPERIENCE MUSIC IN THE MOST SPECTACULAR WAYS!</h1>
                            <h2>On-demand concerts, live events, original series & backstage access.</h2>
                            <div><img className="tv-mobile" src="./images/whitebox/bgtv.png" /></div>
                            <div><a href="https://app.whiteboxplay.com" className=" btn-red">START&nbsp;YOUR&nbsp;30-DAY&nbsp;TRIAL</a></div>
                            <p>AFTER YOUR FREE TRIAL, WHITEBOX PLAY SUBSCRIPTION IS ONLY 7,99$/MONTH. CANCEL ANYTIME.</p>                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="upcoming" className="pt90 pb60 ">
            <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">WE SHARE YOUR PASSION FOR MUSIC</h3>
                        <h4 className="force-desktop-only">Through our live concert streaming,<br></br> exclusive series and more</h4>
                        <h4 className="force-mobile-only">through our live concert streaming, exclusive series <strong>and more!</strong></h4>
                    </div>
     
                    <div className=" col-md-12 listing-image">
                        <div className='content'>
                            
                            <Slider ref={setSliderRef} {...sliderSettings}>
                            {showCards.map((card, index) => (
                            <div key={index}>            
                                <img className="poster-home" src={card.imageSrc}></img>                                
                            </div>
                            ))}
                            </Slider>
                        </div>       
                    </div>
                </div>
            </div>
        </section>
        <section id="lives" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">WE’RE RECONNECTING MUSIC WITH ITS FANS</h3>
                        <h4 className="force-desktop-only">WhiteBox Play reconnects artists and music creators to their fans by delivering and producing high-quality, authentic and exclusive experiences.</h4>
                        <h4 className="force-mobile-only">WhiteBox Play reconnects artists and music creators to their fans by delivering and producing <strong>high-quality, authentic and exclusive experiences.</strong></h4>
                    </div>
                    <div className=" col-md-12 listing-image">                               
                    <div className='content'>
                            
                            <Slider ref={setSliderRef} {...sliderSettings2}>
                            {posterCards.map((card, index) => (
                            <div key={index}>
                                <div className="poster-wrapper">
                                <img className="poster-home" src={card.imageSrc}></img>
                                <span>{card.name}</span>
                                </div>            
                            </div>
                            ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>        

        <section id="arrivals" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">COMING SOON</h3>
                        <h3 className="main-title-2"><strong>Songwriters</strong></h3>
                        <h4>Artists will meet for the first time and will be challenged to compose a new song within 24h while being cut off from the world in an isolated cabin.</h4>
                    </div>                    
                </div>
            </div>
        </section>
        <section id="devices" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">

                        <h3 className="main-title desktop-only">STREAMING ON<br></br> MULTIPLE SCREENS</h3>                        
                        <h3 className="main-title mobile-only">STREAMING ON MULTIPLE SCREENS AND DEVICES</h3>                        
                        <h4 className="desktop-only">It's easier than ever to watch on your phone, tablet, computer or media player in streaming mode.</h4>
                        <div className="desktop-only">
                            <ul className="listing-img-device">
                                <li className="single-img-device"><img src="./images/whitebox/devices/iphone.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/ipad.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/appletv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/android.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/androidtv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/google-play.png" /></li>
                                <li className="single-img-device"><img className="firetv" src="./images/whitebox/devices/firetv.png" /></li>
                                <li className="single-img-device"><img className="amazonAppStore" src="./images/whitebox/devices/amazonappstore.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/roku.png" /></li>
                                <li className="single-img-device"><img className="chromecast" src="./images/whitebox/devices/chromecast.png" /></li>
                            </ul>
                        </div>
                        <div className="btn-link-app btn-red"><a href="https://app.whiteboxplay.com">DOWNLOAD WHITEBOX PLAY APP</a></div>
                        <div className="mobile-only">
                            <ul className="listing-img-device">
                                <li className="single-img-device"><img src="./images/whitebox/devices/iphone.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/ipad.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/appletv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/android.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/androidtv.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/google-play.png" /></li>
                                <li className="single-img-device"><img className="firetv" src="./images/whitebox/devices/firetv.png" /></li>
                                <li className="single-img-device"><img className="amazonAppStore" src="./images/whitebox/devices/amazonappstore.png" /></li>
                                <li className="single-img-device"><img src="./images/whitebox/devices/roku.png" /></li>
                                <li className="single-img-device"><img className="chromecast" src="./images/whitebox/devices/chromecast.png" /></li>
                            </ul>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
        <section id="subscribe" className="pt90 pb60">
        <div className="container">                
               <div className="row">
                    <div className=" col-md-12">
                        <h3 className="main-title">MAY THE <br></br>BINGE-WATCHING BEGIN!</h3>                        
                        <h3 className="main-title-sub">SUBSCRIBE NOW</h3>                        
                        <div className="btn-black-wrapper"><div className="btn-subscribe btn-black"><a href="https://app.whiteboxplay.com">START&nbsp;YOUR&nbsp;30-DAY&nbsp;TRIAL</a></div></div>
                        <h4 className="desktop-only">AFTER YOUR FREE TRIAL, WHITEBOX PLAY SUBSCRIPTION<br></br>IS ONLY 7,99$/MONTH. CANCEL ANYTIME.</h4>
                        <h4 className="mobile-only">AFTER YOUR FREE TRIAL, WHITEBOX PLAY SUBSCRIPTION IS ONLY 7,99$/MONTH. CANCEL&nbsp;ANYTIME.</h4>
                    </div>                    
                </div>
            </div>
        </section>
        <footer id="footer" className="footer  pt90 pb90">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">                        
                        <div className="btn-red"><a target="_blank" rel="noreferrer" href="https://whiteboxplay.zendesk.com/hc/en-us">NEED HELP?</a></div>
                        <h5>We're here to help you 24/7 </h5>
                        <span>&copy;WHITEBOX PLAY 2023</span>
                        <div id="mobile-lang"><a className="lang" href="/fr">Français</a></div>
                    </div>
                </div>
            </div>
        </footer>
      <HomePageContentStyled />
    </HomePageWrapperStyled>
  )
}

export default HomePage;
